.dashboard {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.dashboard-panel {
    width: 100%;
    min-height: 100vh;
    margin-top: 20px;
    margin-bottom: 200px;
    min-width: 1080px;
}

.dashboard-menu-item {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.dashboard-drawer {
    display: flex;
    min-height: 100vh;
    height: 100%;
    min-width: 200px
}