#add-inspection-button {
    width: 100%;
    border-style: dashed;
    border-width: 3px;
    border-color: grey;
    border-radius: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.new-inspection-input-container {
    width: 100%;
    padding: 2px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.language-select {
    width: 200px;
}

.email-dialog-container {
    width: 80%;
    margin: 10px auto;
    min-width: 400px;
}

.to-box {
    display: flex;
    flex-direction: row;
}

.add-icon-box {
    padding-top: 10px;
    width: 100%;
    text-align: center
}

.text-field-with-icon {
    display: flex !important;
    flex-direction: row;
}

.button-container {
    width: 100%;
    text-align: center;
}

.inspection-buttons {
    display: flex !important;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    margin: auto auto;
}