.new-assignments-button {
    margin: 10px;
}

.create-assignment-button {
    width: 100%;
}

.update-assignment-button {
    width: 100%;
}