.textfield {
    width: 100%;
}

.textfield-container {
    padding-top: 20px;
}

.date-picker-container {
    padding-top: 20px;
    display: block;
}