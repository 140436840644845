.calendar {
    width: 100% !important;
    height: 100% !important;
    min-height: 400px;
    border-width: 0cm !important;
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
    background-color: #eeeeee !important;
}

.calendar-tile {
    min-height: 60px;
}

.calendar-tile-other-month {
    background-color: #eeeeee !important;
}

.react-calendar__tile--now {
    background-color: #e8979c !important;
}

.react-calendar__navigation__label {
    background-color: transparent !important;
    color: black
}

.react-calendar__navigation {
    background-color: #dc5e66 !important;
    margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays__weekday {
    background-color: #eeeeee;
}

abbr[title] {
    text-decoration: none;
}

.calendar-tile-active {
    background-color: #dc5e66 !important;
    color: black !important;
}

.react-calendar__tile--active {
    color: black !important;
}