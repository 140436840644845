.spinner-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-edge {
    border-color: grey !important;
    border-style: dashed !important;
    border-width: 1px !important;
}