.template-room-list {
    width: 50%;
}

.template-room-list-item-button {
    display: flex;
    flex-direction: column;
    align-content: left !important;
    align-items: left !important;
    justify-content: left;
}


.template-room-list-item-buttons {
    justify-content: right !important;
}

.template-room-list-item-button:hover {
    background: none !important;
}

.template-room-list-item-button-label {
    width: 100%;
    align-content: left;
}

.template-room-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0px;
    align-items: center;
}